<template>
  <el-main>
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="店员头像：" prop="staff_avatar">
        <ReadyUploadSource @getSource="(val) => (form.staff_avatar = val.path)" :path="form.staff_avatar" @removeThis="() => (form.staff_avatar = '')"></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="店员姓名：" prop="staff_name">
        <el-input v-model="form.staff_name" class="nomalwidth"></el-input>
      </el-form-item>
      <el-form-item label="登录用户名：" prop="staff_account">
        <el-input v-model="form.staff_account" class="nomalwidth"></el-input>
      </el-form-item>
      <el-form-item v-if="form.id" label="登录密码：">
        <el-input v-model="form.staff_password" class="nomalwidth"></el-input>
      </el-form-item>
      <el-form-item v-else label="登录密码：" prop="staff_password">
        <el-input v-model="form.staff_password" class="nomalwidth"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：" prop="staff_phone">
        <el-input v-model="form.staff_phone" class="nomalwidth"></el-input>
      </el-form-item>
      <el-form-item label="角色名称：" prop="role_id">
        <el-select v-model="form.role_id" filterable placeholder="请选择角色名称">
          <el-option v-for="item in roleList" :key="item.role_id" :label="item.role_name" :value="item.role_id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="启用状态：">
        <el-switch v-model="form.state"></el-switch>
      </el-form-item>
    </el-form>
    <Preservation @preservation="saveForm"></Preservation>
  </el-main>
</template>

<script>
import { inspectPhone } from '@/util/verification';
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    Preservation,
    ReadyUploadSource,
  },
  data: function () {
    return {
      form: {
        role_id: '',
        staff_avatar: '',
        staff_name: '',
        staff_account: '',
        staff_password: '',
        staff_phone: '',
        state: !0,
      },
      rules: {
        staff_avatar: [{ required: true, message: '请添加店员头像', trigger: 'blur' }],
        staff_name: [{ required: true, message: '请输入店员姓名', trigger: 'blur' }],
        staff_account: [{ required: true, message: '请输入登录用户名', trigger: 'blur' }],
        staff_password: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
        role_id: [{ required: true, message: '请选择角色名称', trigger: 'blur' }],
        staff_phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!inspectPhone(value)) {
                callback(new Error('请输入电话正确格式'));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
      },
      memberdisabled: false,
      headers: {
        'access-token': localStorage.getItem('token'),
      },
      roleList: [],
      storeinfo: null,
    };
  },
  created() {
    let info = this.$route.query.info;
    if (info) {
      info = JSON.parse(info);
      this.form = info;
      console.log(this.form);
    }
    this.getRoles();
  },
  methods: {
    saveForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let form = JSON.parse(JSON.stringify(this.form));
          form.state = form.state ? 1 : 0;
          this.$axios.post(form.id ? this.$api.store.editStoreUsers : this.$api.store.addStoreUsers, form).then((res) => {
            if (res.code == 0) {
              this.$message.success('保存成功');
              this.$router.push('/store/storeUsersList');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    //获取角色列表
    getRoles() {
      this.$axios
        .post(this.$api.store.rolesList, {
          rows: 10000,
        })
        .then((res) => {
          if (res.code == 0) {
            let list = res.result.list;
            this.roleList = list;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getImg(val) {
      this.form.staff_avatar = val;
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  height: 100%;
  background: #fff;
}
.formlogo {
  width: 120px;
  height: 120px;
  border: 1px solid #dcdfe6;
}
.logodiv {
  width: 120px;
  height: 120px;
  border: 1px solid #dcdfe6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  div {
    font-size: 14px;
    color: #9a9a9a;
    margin-left: 3px;
  }
}
.nomalwidth {
  width: 700px;
}
</style>
